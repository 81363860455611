<template>
  <div>
    <v-card-text class="bold-f-s text-center pc-title"> {{text}}
      <v-icon v-if="show" color="primary" @click="showModal = true">info</v-icon>
    </v-card-text>
    <div class="pc-container option">
      <div class="pc-content">
        <v-card-actions>
          <v-card-title class="text-center bold-f-s"><v-icon>mdi-currency-usd</v-icon> {{approvedAmount}}</v-card-title>
        </v-card-actions>
        <v-card flat class="width-50p p-t-50" style="margin: auto">
          <v-btn @click="addApprovedAmount(25)" icon style="position: absolute; right: -48px; top: 20px">
            <v-icon>add</v-icon>
          </v-btn>
          <v-btn @click="addApprovedAmount(-25)" icon style="position: absolute; left: -48px; top: 20px">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <v-slider
            v-model="approvedAmount"
            :min="program.minimumLoanAmount"
            :max="program.maxLoanAmount"
            thumb-label="always"
            thumb-color="success"
            :thumb-size="42"
            track-color="grey"
            color="#8FBAEC"
            step="25"
          >
            <template v-slot:thumb-label="{ value }">
              {{ toCurrency(value) }}
            </template>
          </v-slider>
          <v-row class="width-full bold-f" style="
      margin: auto;
      margin-top: -20px;
      padding-right: 10px;
      padding-left: 10px
      ">
      <span>
        {{toCurrency(200)}}
      </span>
            <v-spacer></v-spacer>
            <span>
        {{toCurrency(loanAmount)}}
      </span>
          </v-row>
        </v-card>
      </div>
    </div>
    <v-card-actions>
      <v-btn
        large
        color="primary"
        width="240"
        class="m-0 f-s-18"
        @click="next">NEXT
      </v-btn>
    </v-card-actions>
    <v-dialog
      persistent
      max-width="480" v-model="showModal">
      <v-card flat>
        <v-card-text>
          *You can request your Initial Advance in the first sixty (60) days from the initial Disbursement Date and may
          not exceed
          50% of your Amount Financed. If your account remains in good standing for sixty (60) days from the initial
          Disbursement Date,
          you may thereafter request Subsequent Advances up to 75% of the Amount Financed. If your account remains in
          good standing for
          one hundred and twenty days (120) days from the initial Disbursement Date, you may thereafter request
          Subsequent Advances up
          to 100% of the Amount Financed for the remainder of the Advance Period. See details on your Multiple-Advance
          Installment Loan Agreement.
        </v-card-text>
        <v-card-actions>
          <v-btn
            x-large
            block
            color="primary"
            class="width-150"
            @click="showModal=false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { OriginationApi } from '../../api/application'
import { Tool, DataType } from '../../js/core'

const tool = new Tool()

export default {
  components: {},
  data () {
    return {
      page: DataType.PAGES.LOAN_AMOUNT,
      approvedAmount: 0,
      loanAmount: 0,
      text: 'Please select your loan amount',
      reasonCode: 0,
      notes: '',
      showModal: false,
      show: false,
      overlay: true,
      program: {
        minimumLoanAmount: 200,
        maxLoanAmount: 200,
        id: ''
      },
      item: []
    }
  },
  methods: {
    addApprovedAmount (value) {
      if (value > 0) {
        if (this.approvedAmount < this.loanAmount) {
          this.approvedAmount += value
        }
      } else {
        if (this.approvedAmount > this.program.minimumLoanAmount) {
          this.approvedAmount += value
        }
      }
    },
    next () {
      const _this = this
      _this.$store.commit('setOverlay', true)
      OriginationApi.updateOrigination({
        feature: 'payment-option',
        data: {
          loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          approvedAmount: _this.approvedAmount
        }
      }, function () {
        _this.overlay = false
        const stepMessage = {
          currect: DataType.PAGES.LOAN_AMOUNT,
          next: DataType.PAGES.TRANSACTION
        }
        _this.$eventBus.$emit('sendStepToSloth', stepMessage)
        OriginationApi.enteredAndLeft(
          localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          DataType.PAGES.LOAN_AMOUNT.step,
          DataType.PAGES.TRANSACTION.step,
          localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        )
        const portfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
        if (portfolio.individualTransactionMode && portfolio.individualTransactionMode.toString() === '1') {
          _this.$router.push(DataType.PAGES.TRANSACTION.addressPC)
        } else {
          OriginationApi.pageControl(null, function (pageControl) {
            if (
              pageControl !== null &&
              pageControl !== undefined &&
              pageControl.indexOf(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)) > 0
            ) {
              _this.$router.push(DataType.PAGES.MILITARY.addressPC)
            } else {
              _this.$router.push(DataType.PAGES.PREVIEW.addressPC)
            }
          })
        }
      })
    },
    toCurrency (money) {
      return tool.formatCurrencyInt(money)
    },
    submit (data) {
      if (data) {
        this.approvedAmount = data.toString().replace(/[^0-9]/ig, '')
      }
    }
  },
  async created () {
    const _this = this
    _this.$store.commit('setCurrentPage', _this.page)
    _this.$store.commit('setOverlay', true)
    if (parseInt(localStorage.getItem(DataType.COOKIE_KEY.LOAN_TYPE)) === DataType.LoanTypeEnum.MULTIPLE_ADVANCE_LOAN.value) {
      _this.show = true
      _this.text = 'Select your Initial Advance loan amount'
    }
    await OriginationApi.getLoanById({
      loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
      feature: 'PAYMENT_OPTION'
    }, function (result) {
      if (parseInt(localStorage.getItem(DataType.COOKIE_KEY.LOAN_TYPE)) === DataType.LoanTypeEnum.MULTIPLE_ADVANCE_LOAN.value) {
        _this.loanAmount = result.loanAmount / 2
        _this.program.minimumLoanAmount = 200
        _this.program.maxLoanAmount = result.loanAmount / 2
        _this.approvedAmount = result.loanAmount / 2
      } else {
        _this.loanAmount = result.loanAmount
        _this.program.minimumLoanAmount = 200
        _this.program.maxLoanAmount = result.loanAmount
        _this.approvedAmount = result.loanAmount
      }
    })
    _this.$store.commit('setOverlay', false)
  }
}
</script>
<style lang="css">
  .option input {
    text-align: center;
  }

  .option .v-input input {
    max-height: unset;
  }

  .option .v-slider__track-container {
    height: 6px !important;
    border-radius: 15px !important;
  }
  .option .v-slider__thumb-label.success {
    height: 32px !important;
    width: 64px !important;
    border-radius: 5px !important;
    top: -48px !important;
    left: -32px !important;
    transform: none !important;
    font-size: 16px !important;
  }
  .option .v-slider--horizontal .v-slider__thumb-label > * {
    transform: none !important;
  }
</style>
